<template>
  <b-modal
    visible
    scrollable
    centered
    size="lg"
    :title="currentUserData ? 'Пользователь ' + currentUserData.item.displayName: 'Новый пользователь'"
    :hide-footer="isLoading"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <template v-if="isEsia">
        <div class="d-flex">
          <b-form-group
            label="Фамилия"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.lastName"
              type="text"
              disabled
              trim
              @input="validateField('userData.lastName', userData.lastName)"
            />
          </b-form-group>
          <b-form-group
            label="Имя"
            class="w-50"
          >
            <b-form-input
              :value="userData.firstName"
              type="text"
              disabled
              trim
              @input="validateField('userData.firstName', userData.firstName)"
            />
          </b-form-group>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Отчество"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.middleName"
              type="text"
              disabled
              trim
            />
          </b-form-group>
          <b-form-group
            label="Снилс"
            class="w-50"
            :disabled="!!currentUserData"
          >
            <b-form-input
              v-model="userData.snils"
              v-maska="'###-###-### ##'"
              type="text"
              :placeholder="'Введите Снилс'"
              :state="!errors['snils']"
              trim
              @input="validateField('userData.snils', userData.snils)"
            />
            <b-form-invalid-feedback v-if="errors['userData.snils']">
              {{ errors['userData.snils'][0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Номер телефона"
            class="w-50 mr-2"
          >
            <b-form-input
              v-model="userData.phoneNumber"
              type="text"
              :placeholder="'Введите номер телефона'"
              :state="!errors['userData.phoneNumber']"
              trim
              @input="validateField('userData.phoneNumber', userData.phoneNumber)"
            />
            <b-form-invalid-feedback v-if="errors['userData.phoneNumber']">
              {{ errors['userData.phoneNumber'][0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-flex w-50">
            <b-form-group
              label="Дата рождения"
              class="w-50 mr-2"
            >
              <b-form-input
                :value="userData.birthday"
                type="text"
                disabled
                trim
                @input="validateField('userData.birthday', userData.birthday)"
              />
            </b-form-group>

            <b-form-group
              label="Пол"
              class="w-50"
            >
              <b-form-input
                :value="userData.middleName"
                type="text"
                disabled
                trim
              />
            </b-form-group>
          </div>
        </div>

        <div class="d-flex mb-3">
          <b-form-group
            label="Email"
            class="w-50 mr-2"
          >
            <b-form-input
              :value="userData.email"
              type="text"
              disabled
              trim
            />
          </b-form-group>

          <b-form-group
            label="ЕСИА ID"
            class="w-50"
          >
            <b-form-input
              :value="userData.esiaUserId"
              type="text"
              disabled
              trim
            />
          </b-form-group>
        </div>
      </template>

      <template v-else>
        <b-form-group
          label="Фамилия"
        >
          <b-form-input
            v-model="userData.lastName"
            type="text"
            :placeholder="'Введите Фамилию'"
            :state="checkValidations ? !errors['userData.lastName'] : null"
            trim
            @input="validateField('userData.lastName', userData.lastName)"
          />
          <b-form-invalid-feedback v-if="errors['userData.lastName']">
            {{ errors['userData.lastName'][0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Имя"
        >
          <b-form-input
            v-model="userData.firstName"
            type="text"
            :placeholder="'Введите Имя'"
            :state="checkValidations ? !errors['userData.firstName'] : null"
            trim
            @input="validateField('userData.firstName', userData.firstName)"
          />
          <b-form-invalid-feedback v-if="errors['userData.firstName']">
            {{ errors['userData.firstName'][0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Логин"
        >
          <b-form-input
            v-model="userData.login"
            type="text"
            placeholder="Введите логин"
            :state="checkValidations ? !errors['userData.login'] : null"
            trim
            @input="validateField('userData.login', userData.login)"
          />
          <b-form-invalid-feedback v-if="errors['userData.login']">
            {{ errors['userData.login'][0] }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          label="Пароль"
        >
          <b-input-group class="bg-white border-0 rounded">
            <b-form-input
              v-model="userData.password"
              :state="checkValidations ? !errors['userData.password'] : null"
              :type="passwordInputType"
              placeholder="Введите пароль"
              autocomplete="new-password"
              trim
              @input="validateField('userData.password', userData.password)"
            />
            <b-button
              variant="outline"
              :type="$const.PRIMARY_BUTTON"
              class="rounded border-0 passwordBtn"
              @click="passwordDisplay('password')"
            >
              <b-icon
                v-if="passwordShow"
                icon="eye"
              />
              <b-icon
                v-if="!passwordShow"
                icon="eye-slash"
              />
            </b-button>
            <b-form-invalid-feedback v-if="errors['userData.password']">
              {{ errors['userData.password'][0] }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Email"
        >
          <b-form-input
            v-model="userData.email"
            :type="'email'"
            placeholder="Введите email"
            trim
            @input="validateField('userData.email', userData.email)"
          />
        </b-form-group>
      </template>

      <b-form-group
        label="Роль"
        :disabled="disabledFields.roles"
      >
        <b-form-select
          v-model="userData.roleId"
          :state="checkValidations ? !errors['userData.roleId'] : null"
          :options="filteredRoles"
          size="sm"
          value-field="id"
          text-field="name"
          @change="onRoleChange"
          @input="validateField('userData.roleId', userData.roleId)"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Выберите роль
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback v-if="errors['userData.roleId']">
          {{ errors['userData.roleId'][0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="isMedicalWorker"
        label="Доверенность"
      >
        <b-form-input
          v-model="userData.powerOfAttorney"
          :state="checkValidations ? !errors['userData.powerOfAttorney'] : null"
          placeholder="Доверенность"
          trim
          @input="validateField('userData.powerOfAttorney', userData.powerOfAttorney)"
        />
        <b-form-invalid-feedback v-if="errors['userData.powerOfAttorney']">
          {{ errors['userData.powerOfAttorney'][0] }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-if="needToSelectUserClinics">
        <b-form-group
          label="Прикрепленные клиники"
        >
          <div
            v-if="errors['userData.clinicIds']"
            class="invalid-message"
          >
            Необходима как минимум 1 клиника
          </div>

          <b-table-simple
            v-if="userData.clinicIds.length"
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="clinic in userData.clinicIds"
                :key="clinic.id"
              >
                <b-td class="w-100">
                  {{ clinic.name }}
                </b-td>
                <b-td v-if="abilityRemoveClinic">
                  <b-button
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-auto"
                    size="sm"
                    @click="onClinicRemove(clinic.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div
            v-if="abilityAddClinic"
            class="d-flex"
          >
            <b-button
              :variant="clinicTableShow ? 'secondary' : 'primary'"
              :type="$const.PRIMARY_BUTTON"
              class="ml-auto"
              size="sm"
              style="width: 200px"
              @click="toggleClinicTableShow"
            >
              {{ clinicTableShow ? 'Скрыть' : 'Добавить клинику' }}
            </b-button>
          </div>
        </b-form-group>

        <template v-if="clinicTableShow">
          <b-form-input
            v-model="clinicSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск клиники по названию"
            size="sm"
            trim
            class="mb-2"
          />

          <div
            v-if="clinicSearchString && filteredClinicsNameList.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="clinic in filteredClinicsNameList"
                  :key="clinic.id"
                >
                  <b-td class="w-100">
                    {{ clinic.name }}
                  </b-td>
                  <b-td>
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      class="ml-auto"
                      size="sm"
                      @click="onClinicAdd(clinic.id)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="clinicSearchString && !filteredClinicsNameList.length"
            class="ml-2"
          >
            Клиника не найдена
          </small>

          <small
            v-if="!clinicSearchString"
            class="ml-2"
          >
            Введите название клиники
          </small>
        </template>
      </template>
      <template v-if="isInsuranceAdministrator">
        <b-form-group
          :label="insuranceCompaniesTitle"
        >
          <b-table-simple
            v-if="filteredInsuranceCompaniesIds.length"
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="company in filteredInsuranceCompaniesIds"
                :key="company.id"
              >
                <b-td class="w-100">
                  {{ company.title }}
                </b-td>
                <b-td>
                  <b-button
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-auto"
                    size="sm"
                    @click="onCompanyRemove(company.id)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>

          <div class="d-flex">
            <b-button
              :variant="insuranceCompaniesTableShow ? 'secondary' : 'primary'"
              :type="$const.PRIMARY_BUTTON"
              class="ml-auto"
              size="sm"
              style="width: 250px"
              @click="toggleInsuranceCompaniesTableShow"
            >
              {{ insuranceCompaniesTableShow ? 'Скрыть' : 'Добавить страховую компанию' }}
            </b-button>
          </div>
        </b-form-group>
        <template v-if="insuranceCompaniesTableShow">
          <b-form-input
            v-model="insuranceCompaniesSearch"
            type="text"
            debounce="500"
            placeholder="Поиск страховых компаний по названию"
            size="sm"
            trim
            class="mb-2"
          />

          <div
            v-if="insuranceCompaniesSearch && filteredInsuranceCompanies.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="company in filteredInsuranceCompanies"
                  :key="company.id"
                >
                  <b-td class="w-100">
                    {{ company.title }}
                  </b-td>
                  <b-td>
                    <b-button
                      variant="success"
                      :type="$const.PRIMARY_BUTTON"
                      class="ml-auto"
                      size="sm"
                      @click="onCompanyAdd(company.id)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="insuranceCompaniesSearch && !filteredInsuranceCompanies.length"
            class="ml-2"
          >
            Страховая компания не найдена
          </small>

          <small
            v-if="!insuranceCompaniesSearch"
            class="ml-2"
          >
            Введите название страховой компании
          </small>
        </template>
      </template>
      <hr>
      <b-form-checkbox
        v-model="showPhoto"
        size="sm"
        :disabled="!!userData.avatarUrl"
        @click="loadPhoto"
      >
        <span class="text-secondary">
          Загрузить фото
        </span>
      </b-form-checkbox>
      <template v-if="showPhoto">
        <base-photo-edit
          :photo-url-prop="userImageUrl"
          :image-height="100"
          :image-width="100"
          :edit-prop="true"
          :custom-save="true"
          rounded
          @cancel="onCancel"
          @save="onSave"
        />
      </template>

      <hr>

      <template v-if="!isEsia && !isMedicalWorker">
        <b-form-checkbox
          v-model="userData.showPassports"
          class="mt-3"
          size="sm"
        >
          <span class="text-secondary">
            Выгружать паспорта
          </span>
        </b-form-checkbox>

        <b-form-checkbox
          v-model="userData.canAuthorize"
          size="sm"
        >
          <span class="text-secondary">
            Разрешить авторизоваться за пользователя
          </span>
        </b-form-checkbox>
      </template>

      <b-form-checkbox
        v-if="!isClinicAdministrator"
        v-model="userData.canReferralToDuty"
        size="sm"
      >
        <span class="text-secondary">
          Направление на дежурную консультацию
        </span>
      </b-form-checkbox>

      <b-form-checkbox
        v-model="userData.isViewAllAppeals"
        size="sm"
      >
        <span class="text-secondary">
          Показывать все обращения
        </span>
      </b-form-checkbox>
      <b-form-checkbox
        v-model="showPurposeList"
        size="sm"
      >
        <span class="text-secondary">
          Обращение - отображать только эти цели
        </span>
      </b-form-checkbox>
      <div
        v-if="showPurposeList"
        class="mb-3"
      >
        <div class="mb-2">
          Цель обращения
        </div>
        <multiselect
          v-model="userData.appealPurposes"
          :multiple="true"
          :searchable="true"
          :close-on-select="false"
          :hide-selected="true"
          label="title"
          track-by="id"
          select-label="Нажмите, чтобы выбрать"
          selected-label="Выбрано"
          deselect-label="Нажмите, чтобы удалить"
          placeholder="Выберите цель обращения"
          :options="APPEALS_PURPOSES_TEXTS"
        >
          <span slot="noResult">Не удалось найти цели обращения</span>
        </multiselect>
      </div>
      <b-form-checkbox
        v-model="userData.allowCalls"
        size="sm"
      >
        <span class="text-secondary">
          Звонки из CRM
        </span>
      </b-form-checkbox>
      <b-form-checkbox
        v-model="userData.enableMidAuthorization"
        size="sm"
      >
        <span class="text-secondary">
          Авторизация из медиалог
        </span>
      </b-form-checkbox>
      <b-form-group
        v-if="userData.allowCalls"
        class="mt-3"
        label="Sip логин"
      >
        <b-form-input
          v-model="userData.sipLogin"
          type="text"
          :state="checkValidations ? !errors['userData.sipLogin'] : null"
          placeholder="Введите SIP логин"
          trim
          @input="validateField('userData.sipLogin', userData.sipLogin)"
        />
        <b-form-invalid-feedback v-if="errors['userData.sipLogin']">
          {{ errors['userData.sipLogin'][0] }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="userData.allowCalls"
        label="SIP пароль"
      >
        <b-input-group class="bg-white border-0 rounded">
          <b-form-input
            v-model="userData.sipPassword"
            :state="checkValidations ? !errors['userData.sipPassword'] : null"
            :type="passwordSipInputType"
            placeholder="Введите SIP пароль"
            autocomplete="new-password"
            trim
            @input="validateField('userData.sipPassword', userData.sipPassword)"
          />
          <b-button
            variant="outline"
            :type="$const.PRIMARY_BUTTON"
            class="rounded border-0 passwordBtn"
            @click="passwordDisplay('sipPassword')"
            @input="validateField('userData.sipPassword', userData.sipPassword)"
          >
            <b-icon
              v-if="sipPasswordShow"
              icon="eye"
            />
            <b-icon
              v-if="!sipPasswordShow"
              icon="eye-slash"
            />
          </b-button>
          <b-form-invalid-feedback v-if="errors['userData.sipPassword']">
            {{ errors['userData.sipPassword'][0] }}
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
      <b-form-group
        v-if="userData.allowCalls"
        label="Номер"
        class="w-50 mb-1 mr-2"
      >
        <b-form-input
          v-model="userData.sipNumber"
          type="text"
          :state="checkValidations ? !errors['userData.sipNumber'] : null"
          placeholder="Введите номер"
          @keypress="validateSipNumber($event)"
          @input="validateField('userData.sipNumber', userData.sipNumber)"
        />
        <b-form-invalid-feedback v-if="errors['userData.sipNumber']">
          {{ errors['userData.sipNumber'][0] }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="userData.enableMidAuthorization"
        label="ID пользователя медиалога"
        class="w-50 mb-1 mr-2 mt-3"
      >
        <b-form-input
          v-model="userData.midId"
          type="text"
          :state="checkValidations ? !errors['userData.midId'] : null"
          placeholder="Введите ID пользователя медиалога"
          @input="validateField('userData.midId', userData.midId)"
        />
        <b-form-invalid-feedback v-if="errors['userData.midId']">
          {{ errors['userData.midId'][0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        v-if="currentUserData"
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        :disabled="isLoading"
        @click="onSaveUser"
      >
        {{ currentUserData ? 'Изменить' : 'Сохранить пользователя' }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from '@evd3v/date-fns';
import Preloader from '@/components/Preloader';
import { BasePhotoEdit } from '@/components/base';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { showErrorCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import { APPEALS_PURPOSES_TEXTS } from '@/services/appeals/appeals.const';
import { APP_DOCTIS_FILE_SERVER_URL } from '@/app.config';

import { clinicService } from '@/services';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { userViewModalSchema } from '@/validation/zod/user-view-modal/userViewModal.validation';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'UserViewModal',
  components: {
    Preloader,
    BasePhotoEdit,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    userId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    currentUserData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      checkValidations: false,
      passwordShow: true,
      sipPasswordShow: true,
      clinicTableShow: false,
      clinicSearchString: '',
      insuranceCompaniesSearch: '',
      insuranceCompaniesTableShow: false,
      isLoading: false,
      passwordInputType: 'password',
      passwordSipInputType: 'password',
      isEsia: false,
      showPhoto: false,
      showPurposeList: false,
      userData: {
        appealPurposes: [],
        avatarUrl: null,
        snils: null,
        esiaUserId: null,
        birthday: null,
        sex: null,
        phoneNumber: null,
        email: null,
        firstName: null,
        lastName: null,
        login: null,
        sipLogin: null,
        sipPassword: null,
        sipNumber: null,
        middleName: null,
        password: null,
        showPassports: false,
        allowCalls: false,
        canReferralToDuty: false,
        roleId: null,
        clinicIds: [],
        canAuthorize: false,
        isViewAllAppeals: false,
        enableMidAuthorization: false,
        midId: null,
        powerOfAttorney: null,
        insuranceCompaniesIds: [],
      },
      roleList: [],
      clinicsNameList: [],
      tableFields: [
        { key: 'name', label: '' },
        { key: 'actions', label: '' },
      ],
      APPEALS_PURPOSES_TEXTS,
      fieldsToValidate: ['userData.roleId'],
      errors: {},
      insuranceData: [],
      insuranceCompanies: [],
    };
  },
  computed: {
    userImageUrl() {
      return this.userData.avatarUrl
        ? `${APP_DOCTIS_FILE_SERVER_URL}/content/admin-avatars/${this.userData.avatarUrl}`
        : null;
    },
    actualRole() {
      if (this.roleList.length) {
        return this.roleList.find((item) => item.id === this.userData.roleId);
      }

      return {};
    },
    onlyClinicAdministratorInRoles() {
      return this.checkFeatureAccess({ name: 'В фильтре только роль ClinicAdministrator', url: '/users' });
    },
    viewRolesOnlyForMonitoringAdmin() {
      return this.checkFeatureAccess({ name: 'Видеть только роль medical.worker.dis.monitoring', url: '/users' });
    },
    abilityAddClinic() {
      return this.checkFeatureAccess({ name: 'Возможность добавлять прикрепленные клиники', url: '/users' });
    },
    abilityRemoveClinic() {
      return this.checkFeatureAccess({ name: 'Возможность удалить прикрепленную клинику', url: '/users' });
    },
    isMedicalWorker() {
      try {
        return this.actualRole.name === 'medical.worker.dis.monitoring';
      } catch (e) {
        return false;
      }
    },
    filteredRoles() {
      try {
        if (this.viewRolesOnlyForMonitoringAdmin) {
          return this.roleList.filter((item) => ['medical.worker.dis.monitoring', 'methodic.center'].includes(item.name));
        }

        return this.roleList;
      } catch (e) {
        console.error(e);
        return [];
      }
    },
    user() {
      return this.$store.state.Auth.user;
    },
    userRole() {
      return this.roleList.find((r) => r.id === this.userData.roleId);
    },
    needToSelectUserClinics() {
      return this.isClinicAdministrator || this.isDisMonitoring || this.isDisMonitoringTeam || this.isMethodicCenter || this.isClinicAdministratorMid;
    },
    isMethodicCenter() {
      return this.userRole?.name === 'methodic.center';
    },
    isClinicAdministrator() {
      return this.userRole?.name === 'ClinicAdministrator';
    },
    isClinicAdministratorMid() {
      return this.userRole?.name === 'ClinicAdministratorMiD';
    },
    isDisMonitoring() {
      return this.userRole?.name === 'Dis.monitoring.admin';
    },
    isInsuranceAdministrator() {
      return this.userRole?.name === 'insurance.administrator';
    },
    isAdminDisMonitoring() {
      // оставлю, в сохранении непонятная логика
      return this.checkRoleAccess(['Dis.monitoring.admin']);
    },
    isDisMonitoringTeam() {
      return this.userRole?.name === 'Dis.monitoring.team';
    },
    filteredClinicsNameList() {
      if (!this.clinicSearchString) return [];

      return this.clinicsNameList.filter((c) => {
        if (!c.name.toLowerCase().includes(this.clinicSearchString.toLowerCase())) return false; // search by clinic name from clinicSearchString

        return !this.userData.clinicIds.find((cc) => cc.id === c.id); // return false if clinic added to userdata
      });
    },
    filteredInsuranceCompanies() {
      if (!this.insuranceCompaniesSearch) return [];

      return this.insuranceCompanies.filter((e) => {
        if (!e.title.toLowerCase().includes(this.insuranceCompaniesSearch.toLowerCase())) return false;
        return !this.userData.insuranceCompaniesIds.find((elem) => elem.id === e.id);
      });
    },
    filteredInsuranceCompaniesIds() {
      return this.insuranceCompanies.filter((company) => this.userData.insuranceCompaniesIds.includes(company.id));
    },
    disabledFields() {
      return {
        roles: this.onlyClinicAdministratorInRoles || false,
      };
    },
    insuranceCompaniesTitle() {
      if (this.userData.insuranceCompaniesIds.length) {
        return 'Прикреплённые страховые компании';
      }
      return 'Нет прикреплённых страховых компаний';
    },
  },
  watch: {
    showPurposeList(e) {
      if (!e) this.userData.appealPurposes = [];
    },
    isMedicalWorker(value) {
      if (value) {
        this.addFieldsToValidate(['userData.powerOfAttorney']);
        this.makeValidate(['userData.powerOfAttorney']);
      } else {
        this.removeFieldsFromValidate(['userData.powerOfAttorney']);
      }
    },
    'userData.allowCalls': function allowCalls(value) {
      const fieldsToAdd = ['userData.sipLogin', 'userData.sipPassword', 'userData.sipNumber'];
      if (value) {
        this.addFieldsToValidate(fieldsToAdd);
        this.makeValidate(fieldsToAdd);
      } else {
        this.removeFieldsFromValidate(fieldsToAdd);
      }
    },
    'userData.enableMidAuthorization': function enableMidAuthorization(value) {
      if (value) {
        this.addFieldsToValidate(['userData.midId']);
      } else {
        this.removeFieldsFromValidate(['userData.midId']);
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      this.roleList = await this.$store.dispatch(this.$types.ROLES_FETCH);
      this.clinicsNameList = await clinicService.getListNames();
      this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));

      if (this.viewRolesOnlyForMonitoringAdmin) {
        const actualRole = this.roleList.find((item) => item.name === 'medical.worker.dis.monitoring');
        if (actualRole) {
          this.userData.roleId = actualRole.id;
        }

        this.userData.clinicIds = this.clinicsNameList.filter((cl) => this.user.clinicAdminIds.includes(cl.id));

        // if (!this.currentUserData) {
        //   this.userData.clinicIds = this.user.clinicAdminIds.map((item) => ({ id: item }));
        // }
      }

      if (this.currentUserData) {
        const userData = await this.$store.dispatch(this.$types.GET_USER_BY_ID, this.currentUserData.item.id);
        this.isEsia = !!userData.esiaUserId;

        this.userData.showPassports = userData.showPassports;
        this.userData.allowCalls = userData.allowCalls;
        this.userData.canReferralToDuty = userData.referralToDuty;
        this.userData.canAuthorize = userData.canAuthorize;
        this.userData.clinicIds = userData.clinicIds;
        this.userData.insuranceCompaniesIds = userData.insuranceCompaniesAdministration;
        this.userData.powerOfAttorney = userData.powerOfAttorney;

        this.userData.firstName = this.currentUserData.item.firstName;
        this.userData.lastName = this.currentUserData.item.lastName;
        this.userData.login = this.currentUserData.item.login;
        this.userData.sipLogin = userData.sipLogin;
        this.userData.sipPassword = userData.sipPassword;
        this.userData.sipNumber = userData.sipNumber;
        this.userData.email = this.currentUserData.item.email;
        this.userData.roleId = this.currentUserData.item.roleId;
        this.userData.avatarUrl = this.currentUserData.item.avatarUrl;
        this.userData.appealPurposes = this.currentUserData.item.appealPurposes;
        this.userData.isViewAllAppeals = this.currentUserData.item.isViewAllAppeals;
        this.userData.enableMidAuthorization = this.currentUserData.item.enableMidAuthorization;
        this.userData.midId = this.currentUserData.item.midId;
      }
      if (this.userData.avatarUrl) this.showPhoto = true;
      if (this.userData.appealPurposes?.length) {
        this.userData.appealPurposes = APPEALS_PURPOSES_TEXTS.filter((p) => this.userData.appealPurposes.some((el) => el === p.id));
        this.showPurposeList = true;
      }

      if (!this.currentUserData && this.onlyClinicAdministratorInRoles) {
        const clinicAdminRole = this.roleList.find((role) => role.name === 'ClinicAdministrator');
        this.userData.roleId = clinicAdminRole.id;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
    if (this.isEsia) {
      this.addFieldsToValidate(['userData.snils', 'userData.phoneNumber']);
    } else {
      if (!this.currentUserData) this.addFieldsToValidate(['userData.password']);
      this.addFieldsToValidate(['userData.firstName', 'userData.lastName', 'userData.login']);
    }

    if (this.userData.allowCalls) {
      this.addFieldsToValidate(['userData.sipLogin', 'userData.sipPassword', 'userData.sipNumber']);
    }

    if (this.userData.enableMidAuthorization) {
      this.addFieldsToValidate(['userData.midId']);
    }

    if (this.isMedicalWorker) {
      this.addFieldsToValidate(['userData.powerOfAttorney']);
    }
  },
  methods: {
    addFieldsToValidate(fields) {
      fields.forEach((field) => {
        if (!this.fieldsToValidate.includes(field)) {
          this.fieldsToValidate.push(field);
        }
      });
    },
    removeFieldsFromValidate(fields) {
      fields.forEach((field) => {
        const index = this.fieldsToValidate.indexOf(field);
        if (index !== -1) {
          this.fieldsToValidate.splice(index, 1);
        }
      });
    },
    validateField(field, value) {
      const error = validateData(field, value, userViewModalSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },

    makeValidate(fields) {
      const errors = makeValidate(fields, this, userViewModalSchema);
      this.errors = [];
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    onRoleChange() {
      this.userData.canReferralToDuty = false;
    },
    toggleClinicTableShow() {
      this.clinicTableShow = !this.clinicTableShow;
      this.clinicSearchString = '';
    },
    toggleInsuranceCompaniesTableShow() {
      this.insuranceCompaniesTableShow = !this.insuranceCompaniesTableShow;
      this.insuranceCompaniesSearch = '';
    },
    onClinicAdd(clinicId) {
      if (this.userData.clinicIds.find((c) => c.id === clinicId)) return; // return if clinic already in array

      const clinic = this.clinicsNameList.find((c) => c.id === clinicId);
      if (clinic) this.userData.clinicIds.push(clinic);
    },
    onCompanyAdd(companyId) {
      if (this.userData.insuranceCompaniesIds.find((elem) => elem.id === companyId)) return;

      const company = this.insuranceCompanies.find((elem) => elem.id === companyId);
      if (company) this.userData.insuranceCompaniesIds.push(company.id);
    },
    onClinicRemove(clinicId) {
      uiService.showModal(MODALS.USER_REMOVE_MODAL, {
        name: 'UserRemoveModal',
        props: {
          title: 'Удалить клинику?',
        },
        actions: {
          delete: () => {
            this.userData.clinicIds = this.userData.clinicIds.filter((c) => c.id !== clinicId);
          },
        },
      });
    },
    onCompanyRemove(companyId) {
      uiService.showModal(MODALS.USER_REMOVE_MODAL, {
        name: 'UserRemoveModal',
        props: {
          title: 'Удалить страховую компанию?',
        },
        actions: {
          delete: () => {
            this.userData.insuranceCompaniesIds = this.userData.insuranceCompaniesIds.filter((el) => el !== companyId);
          },
        },
      });
    },
    passwordDisplay(passwordType) {
      if (passwordType === 'sipPassword') {
        this.passwordSipInputType = this.passwordSipInputType === 'password' ? 'text' : 'password';
        this.sipPasswordShow = !this.sipPasswordShow;
      } else {
        this.passwordInputType = this.passwordInputType === 'password' ? 'text' : 'password';
        this.passwordShow = !this.passwordShow;
      }
    },
    onClose() {
      this.$emit('input', false);
    },
    async onSaveUser() {
      try {
        this.checkValidations = true;

        this.makeValidate(this.fieldsToValidate);
        if (Object.keys(this.errors).length > 0) {
          showValidationErrorMessage();
          return;
        }
        // !!! здесь трогать не стал
        if (
          !(this.isClinicAdministrator || this.isClinicAdministratorMid || this.isDisMonitoring || this.isDisMonitoringTeam || this.isMethodicCenter)
          && !this.isAdminDisMonitoring) this.userData.clinicIds = [];

        let method = this.currentUserData ? 'USER_EDIT' : 'USER_CREATE';
        if (!this.currentUserData && this.isEsia) method = 'USER_CREATE_FOR_ESIA';
        const { appealPurposes } = this.userData;
        await this.$store.dispatch(this.$types[method], {
          ...this.userData,
          appealPurposes: appealPurposes ? appealPurposes.map((purpose) => purpose.id) : null,
          id: this.currentUserData ? this.currentUserData.item.id : null,
          clinicIds: this.userData.clinicIds.map((c) => c.id),
          insuranceCompaniesAdministration: this.userData.insuranceCompaniesIds,
        });
        this.$store.commit(this.$types.TOGGLE_FETCH_USERS_TRIGGER); // сделает тогл триггера и обновит юзеров
        this.onClose();
      } catch (e) {
        console.error(e);
        showErrorCustomMessage(`Что-то пошло не так. ${e}`);
      }
    },
    loadPhoto() {
      this.showPhoto = !this.showPhoto;
    },
    onCancel() {
      this.userData.avatarUrl = null;
    },
    async onSave(file) {
      try {
        this.isLoading = true;
        const { data } = await this.$store.dispatch(this.$types.USER_AVATAR_UPLOAD, { file });
        this.userData.avatarUrl = data;
      } finally {
        this.isLoading = false;
      }
    },
    validateSipNumber: (e = window.event) => {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 43) {
        e.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordBtn:focus {
  box-shadow: none;
}
</style>
